import { useState } from 'react';
import styles from './styles.module.css';
import IconShowHide from '../Icons/IconShowHide';

export default function CustomInput({
  label = '',
  type = 'text',
  value = '',
  setValue,
  error = '',
  disabled = false,
}) {
  const [isShow, setIsShow] = useState(true);
  const [blurred, setBlurred] = useState(false);

  const handleInput = e => {
    const val = e.target.value;
    setValue(val);
  };

  const setVisibility = e => {
    e.preventDefault();
    e.stopPropagation();
    setIsShow(!isShow);
  };

  return (
    <div className={styles.container}>
      <label
        htmlFor={label}
      >
        { label }
      </label>
      <input
        id={label}
        type={type === 'password' && !isShow ? 'text' : type}
        className={`${type === 'password' ? styles.password : ''} ${styles.input} ${(error && blurred) && styles.inputError}`}
        value={value}
        disabled={disabled}
        onChange={handleInput}
        onBlur={() => setBlurred(true)}
      />
      {
        type === 'password' ?
          (
            <button
              type="button"
              className={styles.iconButton}
              onClick={setVisibility}
            >
              <IconShowHide isShow={isShow} />
            </button>
          )
          :
          null
      }
      { error && blurred ? <span className={styles.errorMessage}>{ error }</span> : null }
    </div>
  );
}
