const IconShowHide = ({ fill = '--colour-utility-base', isShow = true }) => (
  <svg
    fill={fill}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 48 48"
  >
    <defs>
      <rect
        id="path-1"
        x="0"
        y="0"
        width="48"
        height="48"
      />
    </defs>
    { isShow ? (
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fillRule="evenodd"
      >
        <g id="icon/hide">
          <g id="edit-/-hide-(Background/Mask)" />
          <path
            id="edit-/-hide"
            d="M8.82492352,6.00354004 L42.7625999,39.9412155 L42.7661057,39.9377098 L42.7695541,39.9411583 L39.9411278,42.7695847 L33.2274475,36.0559044 C30.7136402,37.228405 27.6664448,38.0000343 24,38.0000343 C8.81599331,38.0000343 4.25263602,24.7660332 4.20887235,24.6320343 L4,24.0000343 L4.21086146,23.3680344 C4.23725128,23.2833824 6.06808233,17.9708786 11.2409463,14.0694036 L6,8.82846069 L8.82492352,6.00354004 L8.82492352,6.00354004 Z M30.1733913,33.0018463 L26.5789528,29.4074097 C25.7951984,29.7867355 24.9197464,30.0000343 24,30.0000343 C20.7159996,30.0000343 18,27.2840347 18,24.0000343 C18,23.0802879 18.2132988,22.204834 18.5926228,21.4210796 L14.0726433,16.9011002 C10.5379605,19.3318863 8.83074284,22.6195564 8.23313999,24.0000343 C9.23174763,26.308033 13.3554792,34.0000343 24,34.0000343 C26.3680172,34.0000343 28.4133549,33.6193237 30.1733913,33.0018463 L30.1733913,33.0018463 Z M24,10.0000336 C39.1840019,10.0000336 43.7473602,23.2340317 43.7911263,23.3680344 L44,24.0000343 L43.789135,24.6320343 C43.7685471,24.6980782 42.6496048,27.9462872 39.7021141,31.223877 L36.9187851,28.4405479 C38.491539,26.6592503 39.3717346,24.9127731 39.7668571,24.0000343 C38.7682495,21.6920328 34.644516,14.0000334 24,14.0000334 C23.4950504,14.0000334 23.0047741,14.0173445 22.5288086,14.0505705 L19.0004368,10.5221992 C20.5221882,10.189075 22.1852379,10.0000336 24,10.0000336 Z"
          />
        </g>
      </g>
    ) : (
      <g
        id="icon/show"
        stroke="none"
        strokeWidth="1"
        fillRule="evenodd"
      >
        <g>
          <g id="edit-/-show-(Background/Mask)" />
          <path
            id="edit-/-show"
            d="M18,24 C18,27.2839999 20.7160001,30 24,30 C27.2820001,30 30,27.2839999 30,24 C30,20.7179999 27.2820001,18 24,18 C20.7160001,18 18,20.7179999 18,24 Z M4.21086146,23.368 L4,24 L4.20887235,24.632 C4.25263602,24.766 8.81599331,38 24,38 C39.1840029,38 43.7473606,24.766 43.789135,24.632 L44,24 L43.7911263,23.368 C43.7473626,23.234 39.1840029,10 24,10 C8.81599331,10 4.25263587,23.234 4.21086146,23.368 Z M8.23313999,24 C9.235726,21.684 13.3614473,14 24,14 C34.644516,14 38.7682498,21.6919999 39.7668571,24 C38.7642711,26.316 34.6385489,34 24,34 C13.3554802,34 9.23174739,26.3080001 8.23313999,24 Z"
          />
        </g>
      </g>
    )}
  </svg>
);

export default IconShowHide;