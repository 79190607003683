const IconChevronDown = ({ fill = '--colour-utility-base' }) => (
  <svg
    fill={fill}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 48 48"
  >
    <g>
      <path d="M23.9998169,32.4246826 L36.0206337,20.4038696 L33.1922054,17.5754395 L23.9998188,26.767828 L14.8074324,17.5754395 L11.9790039,20.4038663 L23.9998169,32.4246826 Z"></path>
    </g>
  </svg>
);

export default IconChevronDown;