import Logo from '@/Logo';
import styles from './styles.module.css';
import BufferImage from './BufferImage';

export default function Loading() {
  return (
    <div
      className={styles.loading}
    >
      <div
        className={styles.content}
      >
        <Logo />
        <BufferImage />
      </div>
    </div>
  );
}
