import BufferImage from '../Loading/BufferImage';
import styles from './styles.module.css';
import Logo from '@/Logo';

export default function LogoContainerLayout({ loading = false, children }) {
  return (
    <div>
      <div className={styles.logo}>
        <Logo fill="var(--colour-brand-mandy)" />
      </div>
      <div className={styles.mainContentCard}>
        { !loading ? children :
          <BufferImage />
        }
      </div>
    </div>
  );
}
