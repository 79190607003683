// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._5piFH4U1gGCP29roGxVl4g\\=\\= {\n  background-color: #E96061;\n  position: fixed;\n  height: 100%;\n  width: 100%\n}\n\n._99e7\\+U5T43dyC-eDd-YJyw\\=\\= {\n  position: fixed;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  text-align: center;\n}\n\n._99e7\\+U5T43dyC-eDd-YJyw\\=\\= > svg {\n  min-width: 300px;\n  max-width: 600px;\n}", "",{"version":3,"sources":["webpack://./src/components/Loading/styles.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,eAAe;EACf,YAAY;EACZ;AACF;;AAEA;EACE,eAAe;EACf,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":[".loading {\n  background-color: #E96061;\n  position: fixed;\n  height: 100%;\n  width: 100%\n}\n\n.content {\n  position: fixed;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  text-align: center;\n}\n\n.content > svg {\n  min-width: 300px;\n  max-width: 600px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loading": "_5piFH4U1gGCP29roGxVl4g==",
	"content": "_99e7+U5T43dyC-eDd-YJyw=="
};
export default ___CSS_LOADER_EXPORT___;
