const Logo = ({ fill = 'white' }) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg"
    version="1.2"
    x="0px"
    y="0px"
    viewBox="96.86 69.28 647.09 176.56"
  >
    <defs>
      <style>{`
    .cls-1 {
      fill: #111821;
    }

    .cls-1, .cls-2 {
      stroke-width: 0px;
    }

    .cls-2 {
      fill: `}{fill}{`;
    }
    `}</style>
    </defs>
    <g>
      <path className="cls-2" d="M194.66,97.37c0,5.14-4.11,9.25-9.25,9.25s-9.1-4.11-9.1-9.25,3.96-9.1,9.1-9.1,9.25,3.96,9.25,9.1"/>
      <path className="cls-2" d="M154.79,93.22v8.43c0,2.73-2.21,4.94-4.94,4.94h-48.47c-2.5,0-4.52-2.03-4.52-4.52v-4.92c0-4.91,3.97-8.88,8.88-8.88h44.11c2.73,0,4.94,2.22,4.94,4.94"/>
      <path className="cls-2" d="M96.86,177.07c0-5.13,4.11-9.25,9.25-9.25s9.1,4.11,9.1,9.25-3.96,9.1-9.1,9.1-9.25-3.96-9.25-9.1"/>
      <path className="cls-2" d="M136.53,181.21v-8.43c0-2.73,2.21-4.94,4.94-4.94h48.7c2.5,0,4.52,2.03,4.52,4.52v5.46c0,4.6-3.73,8.34-8.33,8.34h-44.88c-2.73,0-4.94-2.22-4.94-4.94"/>
      <path className="cls-2" d="M190.18,146.38h-84.56c-4.83,0-8.75-3.92-8.75-8.75v-5.08c0-2.47,2.01-4.48,4.48-4.48h84.68c4.77,0,8.64,3.87,8.64,8.64v5.2c0,2.47-2.01,4.48-4.48,4.48"/>
      <path className="cls-2" d="M372.62,171.18c-16.15,0-27.47-13.1-27.47-29.42s11.33-29.42,27.47-29.42,27.47,13.09,27.47,29.42-11.32,29.42-27.47,29.42M372.62,95.27c-25.79,0-45.1,19.92-45.1,46.49s19.3,46.5,45.1,46.5c9.41,0,17.91-2.7,24.95-7.36,2.17-1.55,2.51-2.41,2.51-2.41v2.41c0,2.92,2.38,5.3,5.3,5.3h7.1c2.88,0,5.22-2.34,5.22-5.22v-39.22c0-26.57-19.3-46.49-45.09-46.49"/>
      <path className="cls-2" d="M254.13,167.84c1.96-2.79,5.73-3.53,8.64-1.75,6.92,4.22,14.21,6.42,21.38,6.42,7.78,0,15.56-4.17,15.56-11.2,0-10.25-14.8-10.82-27.9-15.18-10.82-3.61-20.5-10.43-20.5-22.96,0-19.17,17.08-27.9,33.02-27.9,9.76,0,17.51,2.32,23.74,5.86,3.32,1.89,4.24,6.27,2,9.36l-1.85,2.58c-1.96,2.71-5.6,3.36-8.53,1.76-4.3-2.36-9.07-3.8-14.23-3.8-8.72,0-16.13,4.17-16.13,11.2,0,6.07,7.02,8.16,15.94,10.43,14.04,3.61,32.45,9.87,32.45,28.28,0,16.89-15.75,27.32-33.22,27.32-11.7,0-21.86-2.95-30.59-8.43-3.1-1.95-3.89-6.15-1.79-9.15l1.99-2.84Z"/>
      <path className="cls-2" d="M571.77,166.96c1.61-2.3,4.75-2.95,7.12-1.45,7.19,4.59,14.8,6.99,22.29,6.99s15.55-4.17,15.55-11.2c0-10.25-14.8-10.82-27.9-15.18-10.82-3.61-20.5-10.43-20.5-22.96,0-19.17,17.09-27.9,33.02-27.9,10.5,0,18.67,2.68,25.12,6.67,2.55,1.59,3.13,5.08,1.37,7.52l-3.21,4.44c-1.61,2.23-4.64,2.81-7.01,1.43-4.54-2.65-9.61-4.32-15.13-4.32-8.73,0-16.13,4.17-16.13,11.2,0,6.07,7.02,8.16,15.94,10.43,14.05,3.61,32.46,9.87,32.46,28.28,0,16.89-15.75,27.32-33.22,27.32-12.15,0-22.64-3.18-31.59-9.07-2.52-1.66-3.21-5.07-1.48-7.54l3.28-4.68Z"/>
      <path className="cls-2" d="M440.5,69.28h7.42c2.93,0,5.31,2.38,5.31,5.31v106.4c0,2.89-2.34,5.23-5.22,5.23h-7.5c-2.93,0-5.31-2.38-5.31-5.31v-106.32c0-2.93,2.38-5.31,5.31-5.31"/>
      <path className="cls-2" d="M734.67,88.27c5.15,0,9.28,4.12,9.28,9.28s-4.12,9.13-9.28,9.13-9.13-3.98-9.13-9.13,3.98-9.28,9.13-9.28"/>
      <path className="cls-2" d="M731.01,120.8h7.47c2.99,0,5.42,2.43,5.42,5.43v54.57c0,3-2.43,5.42-5.42,5.42h-7.47c-3,0-5.43-2.43-5.43-5.42v-54.57c0-3,2.43-5.43,5.43-5.43"/>
      <path className="cls-2" d="M704.17,134.49v5.6c0,3-2.43,5.42-5.42,5.42h-38.07c-2.99,0-5.42-2.43-5.42-5.42v-5.6c0-2.99,2.43-5.42,5.42-5.42h38.07c3,0,5.42,2.43,5.42,5.42"/>
      <path className="cls-2" d="M513.82,111.02c10.62,0,20.87,7.97,23.53,19.35h-48.59c3.03-11.57,13.47-19.35,25.05-19.35M545.5,163.96c-1.99-1.89-5.15-2.02-7.15-.15-4.89,4.57-11.63,8.69-22.26,8.69-15.56,0-27.89-11.95-28.84-26.95h63.61c2.9.06,5.19-2.39,5.21-5.28-.67-25.64-18.44-45.02-42.83-45.02s-43.46,19.73-43.46,46.5,20.12,46.49,46.12,46.49c15.34,0,26.78-5.79,33.97-12.71,2.13-2.06,2-5.52-.14-7.56l-4.23-4.03Z"/>
    </g>
    <g>
      <path className="cls-1" d="M260.22,213.52h2.98l9.12,23.84h-2.1l-2.27-5.83h-12.51l-2.27,5.83h-2.1l9.15-23.84ZM267.31,229.65l-5.46-14.21h-.27l-5.49,14.21h11.22Z"/>
      <path className="cls-1" d="M284.39,229.69h2.14c0,4.1,2.92,6.58,7.7,6.58,4.37,0,7.05-2,7.05-5.05,0-3.7-3.73-4.24-7.63-4.85-4.31-.68-8.85-1.7-8.85-6.71,0-4.17,3.25-6.98,8.82-6.98s9.26,3.05,9.26,7.93l-2.07.03c-.03-3.73-2.75-6.04-7.15-6.04s-6.71,2.03-6.71,4.98c0,3.56,3.49,4.17,7.26,4.75,4.41.71,9.22,1.59,9.22,6.78,0,4.34-3.46,7.09-9.22,7.09s-9.8-3.25-9.8-8.51Z"/>
      <path className="cls-1" d="M323.48,237.35h-2v-2.78h-.27c-1.15,2.1-3.29,3.42-5.83,3.42-4.34,0-7.05-3.22-7.05-7.66v-10.04h1.97v10.04c0,3.39,2,5.83,5.32,5.83s5.87-2.48,5.87-5.8v-10.07h2v17.05Z"/>
      <path className="cls-1" d="M328.66,239.72l2.03-.64c.64,2.95,3.32,4.92,6.85,4.92,4,0,6.75-2.75,6.75-6.81v-3.12h-.27c-1.46,2.37-3.8,3.83-6.85,3.83-5.15,0-8.85-3.8-8.85-9.05s3.7-9.19,8.85-9.19c3.12,0,5.46,1.49,6.88,3.9h.27v-3.25h1.97v16.88c0,5.15-3.59,8.65-8.82,8.65-4.47,0-7.97-2.44-8.81-6.1ZM344.36,228.84c0-4.27-2.98-7.39-7.02-7.39s-7.05,3.12-7.05,7.39,3.02,7.26,7.05,7.26,7.02-3.09,7.02-7.26Z"/>
      <path className="cls-1" d="M350.87,233.38c0-2.88,2.34-4.92,7.39-5.66l5.19-.78v-.81c0-2.68-1.93-4.48-4.88-4.48s-4.85,1.59-4.81,4h-2c0-3.49,2.78-5.87,6.85-5.87s6.85,2.58,6.85,6.37v11.19h-2v-2.88h-.27c-1.22,2.27-3.56,3.49-6.44,3.49-3.56,0-5.87-1.8-5.87-4.58ZM363.45,230.47v-1.76l-5.05.75c-4,.64-5.56,2.03-5.56,3.9s1.59,2.88,4.14,2.88c3.9,0,6.48-2.48,6.48-5.76Z"/>
      <path className="cls-1" d="M371.04,220.3h1.97v2.71h.27c.78-1.83,2.51-3.05,4.58-3.05.85,0,1.73.2,2.34.51v1.97c-.85-.44-1.8-.64-2.58-.64-2.75,0-4.58,2.51-4.58,6.2v9.36h-2v-17.05Z"/>
      <path className="cls-1" d="M383.45,225.45c0-7.42,5.15-12.78,12.38-12.78,5.46,0,10.21,3.76,11.32,8.68l-2.1.27c-.92-3.97-4.81-7.02-9.22-7.02-6,0-10.24,4.51-10.24,10.85s4.24,10.82,10.24,10.82c4.44,0,8.31-2.95,9.26-6.78l2.03.41c-1.08,4.71-5.83,8.31-11.29,8.31-7.22,0-12.38-5.36-12.38-12.75Z"/>
      <path className="cls-1" d="M412.46,213.52h10.14c5.12,0,8.41,2.71,8.41,6.98,0,4.03-3.19,6.78-7.87,6.88h-.75l8.85,9.97h-2.71l-8.65-9.87h-5.29v9.87h-2.14v-23.84ZM422.6,225.52c3.87,0,6.27-1.93,6.27-5.02s-2.37-5.05-6.27-5.05h-8v10.07h8Z"/>
      <path className="cls-1" d="M436.33,213.52h3.42l8.98,20.92h.27l8.95-20.92h3.42v23.84h-2.14v-21.12h-.27l-9.05,21.12h-2.14l-9.02-21.06h-.27v21.06h-2.17v-23.84Z"/>
      <path className="cls-1" d="M475.52,225.45c0-7.42,5.15-12.78,12.38-12.78,5.46,0,10.21,3.76,11.32,8.68l-2.1.27c-.92-3.97-4.81-7.02-9.22-7.02-6,0-10.24,4.51-10.24,10.85s4.24,10.82,10.24,10.82c4.44,0,8.31-2.95,9.26-6.78l2.03.41c-1.08,4.71-5.83,8.31-11.29,8.31-7.22,0-12.38-5.36-12.38-12.75Z"/>
      <path className="cls-1" d="M503.08,228.84c0-5.39,3.76-9.22,9.02-9.22s9.02,3.83,9.02,9.22-3.76,9.19-9.02,9.19-9.02-3.83-9.02-9.19ZM519.12,228.84c0-4.27-2.95-7.39-7.02-7.39s-7.02,3.12-7.02,7.39,2.92,7.36,7.02,7.36,7.02-3.12,7.02-7.36Z"/>
      <path className="cls-1" d="M525.93,220.3h1.93v2.51h.27c.95-1.9,2.81-3.19,5.29-3.19,2.68,0,4.88,1.53,5.73,3.83h.27c.95-2.31,2.98-3.8,5.87-3.8,3.97,0,6.71,2.98,6.71,7.36v10.34h-2v-10.38c0-3.25-2-5.53-4.88-5.53-3.08,0-5.19,2.27-5.19,5.53v10.38h-2v-10.38c0-3.29-1.93-5.53-4.85-5.53-3.08,0-5.15,2.24-5.15,5.53v10.38h-2v-17.05Z"/>
      <path className="cls-1" d="M575.56,228.84c0,5.32-3.76,9.19-8.82,9.19-3.15,0-5.63-1.53-6.88-4.03h-.27v11.15h-2v-24.85h2v3.39h.27c1.25-2.51,3.7-4.07,6.85-4.07,5.09,0,8.85,3.87,8.85,9.22ZM559.52,228.84c0,4.17,2.98,7.36,7.05,7.36s7.02-3.19,7.02-7.36-2.98-7.39-7.02-7.39-7.05,3.19-7.05,7.39Z"/>
      <path className="cls-1" d="M579.05,233.38c0-2.88,2.34-4.92,7.39-5.66l5.19-.78v-.81c0-2.68-1.93-4.48-4.88-4.48s-4.85,1.59-4.81,4h-2c0-3.49,2.78-5.87,6.85-5.87s6.85,2.58,6.85,6.37v11.19h-2v-2.88h-.27c-1.22,2.27-3.56,3.49-6.44,3.49-3.56,0-5.87-1.8-5.87-4.58ZM591.62,230.47v-1.76l-5.05.75c-4,.64-5.56,2.03-5.56,3.9s1.59,2.88,4.14,2.88c3.9,0,6.48-2.48,6.48-5.76Z"/>
      <path className="cls-1" d="M599.22,220.3h2v2.78h.27c1.15-2.1,3.29-3.42,5.87-3.42,4.31,0,7.02,3.22,7.02,7.66v10.04h-1.97v-10.04c0-3.39-2-5.83-5.29-5.83s-5.9,2.48-5.9,5.8v10.07h-2v-17.05Z"/>
      <path className="cls-1" d="M618.61,244.44v-1.8c.71.24,1.53.41,2.14.41,2.51,0,3.46-2.65,4.78-5.8l-7.83-16.95h2.1l6.58,14.31h.27l5.66-14.31h2.1l-6.92,17.19c-1.66,4.14-3.12,7.39-6.51,7.39-.78,0-1.73-.17-2.37-.44Z"/>
    </g>
  </svg>
);

export default Logo;