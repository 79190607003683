import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomButton from '@/components/CustomButton/CustomButton';
import CustomInput from '@/components/CustomInput/CustomInput';
// eslint-disable-next-line
// @ts-ignore
import { httpRequest, t } from '@sales-i/utils';
import styles from './styles.module.css';
import LogoContainerLayout from '../Layout/LogoContainerLayout';

export const emailRegex = '^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$';
export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!%*?&()])(?!.*\s).{8,}$/;

export default function ResetPassword() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [disabled, setDisabled] = useState(true);

  const [validLength, setValidLength] = useState(false);
  const [validLower, setValidLower] = useState(false);
  const [validUpper, setValidUpper] = useState(false);
  const [validSpecial, setValidSpecial] = useState(false);
  const [validDigit, setValidDigit] = useState(false);
  const [validNoSpaces, setValidNoSpaces] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const email = searchParams.get('email');
    const token = searchParams.get('token');
    setEmail(email);
    setToken(token);
  }, [location.search]);

  useEffect(() => {
    const valid = passwordRegex.test(password);
    if (valid) {
      setPasswordError('');
    } else {
      setPasswordError(t('You must enter a valid password'));
    }

    if (password.length >= 8) {
      setValidLength(true);
    } else {
      setValidLength(false);
    }

    if (/[a-z]/.test(password)) {
      setValidLower(true);
    } else {
      setValidLower(false);
    }

    if (/[A-Z]/.test(password)) {
      setValidUpper(true);
    } else {
      setValidUpper(false);
    }

    if (/[@$!%*?&()]+/.test(password)) {
      setValidSpecial(true);
    } else {
      setValidSpecial(false);
    }

    if (/[0-9]/.test(password)) {
      setValidDigit(true);
    } else {
      setValidDigit(false);
    }

    if (!/\s/.test(password)) {
      setValidNoSpaces(true);
    } else {
      setValidNoSpaces(false);
    }
  }, [password]);

  useEffect(() => {
    const pattern = RegExp(emailRegex);
    setDisabled(!pattern.test(email) || !token || passwordError !== '');
  }, [email, token, passwordError]);

  const resetPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await httpRequest('post', 'subjects/resetpassword', {
        email,
        token,
        password
      },
      {
        version: 2,
        area: 'iam',
      });
      navigate('/');
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <LogoContainerLayout loading={loading}>
      <form
        onSubmit={resetPassword}
      >
        <CustomInput
          label={t('Email')}
          value={email}
          setValue={setEmail}
          disabled
        />
        <CustomInput
          label={t('New Password')}
          value={password}
          setValue={setPassword}
          type="password"
          error={passwordError}
        />

        <p>{ t('Password requirements') }</p>
        <ul className={styles.passwordRequirements}>
          <li
            className={`${validLength ? styles.valid : styles.invalid}`}
          >
            { t('At least 8 characters') }
          </li>
          <li
            className={`${validLower ? styles.valid : styles.invalid}`}
          >
            { t('At least 1 lower case character') }
          </li>
          <li
            className={`${validUpper ? styles.valid : styles.invalid}`}
          >
            { t('At least 1 upper case character') }
          </li>
          <li
            className={`${validDigit ? styles.valid : styles.invalid}`}
          >
            { t('At least 1 digit') }
          </li>
          <li
            className={`${validNoSpaces ? styles.valid : styles.invalid}`}
          >
            { t('No white spaces') }
          </li>
          <li
            className={`${validSpecial ? styles.valid : styles.invalid}`}
          >
            { t('At least 1 special character (e.g. @$!%*?&())') }
          </li>
        </ul>
        <div className={styles.submitBtn}>
          <CustomButton
            label={t('Update')}
            type="submit"
            disabled={disabled}
          />
        </div>
      </form>
    </LogoContainerLayout>
  );
}
