import { useState, useEffect } from 'react';
import CustomButton from '@/components/CustomButton/CustomButton';
import CustomInput from '@/components/CustomInput/CustomInput';
// eslint-disable-next-line
// @ts-ignore
import { httpRequest, t } from '@sales-i/utils';
import styles from './styles.module.css';
import LogoContainerLayout from '../Layout/LogoContainerLayout';

export const emailRegex = '^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$';

export default function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const pattern = RegExp(emailRegex);
    setDisabled(!pattern.test(email));
  }, [email]);

  const resetPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await httpRequest('post', 'subjects/generatepasswordreset', {
        email,
      }, {
        area: 'iam',
        version: 2,
      });
      setLoading(false);
      setSuccess(true);
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <LogoContainerLayout loading={loading}>
      {!success ? (
        <form
          onSubmit={resetPassword}
        >
          <CustomInput
            label={t('Email')}
            value={email}
            setValue={setEmail}
          />
          <div className={styles.submitBtn}>
            <CustomButton
              label={t('Send Reset Link')}
              type="submit"
              disabled={disabled}
            />
          </div>
        </form>
      ) : (
        <>
          <p>{ t('If your email address exists, you will receive an email with a reset password link shortly.') }</p>
          <p><a href="/">{ t('Return to Login') }</a></p>
        </>
      )}
    </LogoContainerLayout>
  );
}
