import loadingImage from './loading.gif';

const BufferImage = () => (
  <div
    style={{ width: '100px', margin: '0 auto' }}
  >
    <img
      src={loadingImage}
      alt="loading..."
    />
  </div>
);

export default BufferImage;