// eslint-disable-next-line
// @ts-ignore
import { httpRequest, } from '@sales-i/utils';
import Cookies from 'universal-cookie';

const cookies = new Cookies(null, { path: '/' });

const masquerading = {
  user: false,

  getUserDetails: async function () {
    const data = await httpRequest('get', 'subjects/me', {}, { area: 'iam' });
    this.user = data;
    return this.user;
  },

  getTenants: async function () {
    if (!this.user) return;
    try {
      const resp = await httpRequest('get', `subjects/${this.user.id}/tenants?sort=name:asc`, {}, { area: 'iam' });

      return resp;
    } catch (e) {
      return [];
    }
  },

  tenantLogin: async function (tenantId) {
    if (!tenantId) return;
    try {
      const resp = await httpRequest('post', 'subjects/tenantlogin', { tenant_id: tenantId }, { area: 'iam' });
      // if (process.env.NODE_ENV === 'development') {
      cookies.set('token', resp.token, {
        path: '/',
        sameSite: 'none',
        secure: true // Ensures cookie is sent over HTTPS
      });
      // }

      return resp;
    } catch (e) {
      if (e.response) {
        return { success: false, status: e.response.status, err_code: `errors.${e.response.data.code}` };
      } else {
        return { success: false, err_code: 'errors.unexpected_error' };
      }
    }
  },

  getSubjects: async function () {
    if (!this.user) return;
    try {
      const resp = await httpRequest('get', 'tenants/current/subjects?status=active', {}, { area: 'iam' });

      return resp.map(u => ({
        ...u,
        full_name: `${u.first_name} ${u.last_name}`
      }));
    } catch (e) {
      if (e.response) {
        return { success: false, status: e.response.status, err_code: `errors.${e.response.data.code}` };
      } else {
        return { success: false, err_code: 'errors.unexpected_error' };
      }
    }
  },

  masquerade: async function (subjectId, subjectEmail) {
    if (!subjectId || !subjectEmail) return;
    try {
      const resp = await httpRequest('post', 'subjects/masquerade', {
        id: subjectId,
        email: subjectEmail
      }, { area: 'iam' });
      // if (process.env.NODE_ENV === 'development') {
      cookies.set('token', resp.token), {
        path: '/',
        sameSite: 'none',
        secure: true // Ensures cookie is sent over HTTPS
      };
      // }

      return resp;
    } catch (e) {
      if (e.response) {
        return { success: false, status: e.response.status, err_code: `errors.${e.response.data.code}` };
      } else {
        return { success: false, err_code: 'errors.unexpected_error' };
      }
    }
  }
};

export default masquerading;
